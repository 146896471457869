@import '../../../../assets/sass/variables';

@keyframes chart {
	100% {
		background-position: -2064px 0;
	}
}

@keyframes chart-appearing {
	100% {
		transform: translateY(20px);
		opacity: 1;
	}
}

.component {
	width: 100%;
	height: var(--app-height);
	position: relative;
	overflow: hidden;
}

.chart {
	width: 100%;
	position: absolute;
	top: -20px;
	left: 0;
	opacity: 0;
	animation: chart-appearing 1.5s cubic-bezier(0.28, 0.07, 0.21, 0.87) 1 forwards;

	&::before {
		position: absolute;
		top: 140px;
		left: 0;
		width: 100%;
		height: 245px;
		background-repeat: repeat-x;
		background-position: 0 0;
		background-size: auto 100%;
		background-image: url(../images/chart.png);
		content: '';
		animation: chart 100s linear infinite;
	}
}

.bottom {
	width: 100%;
	position: absolute;
	bottom: 20px;
	gap: 20px;
	left: 0;
	display: grid;
}

.enter {
	opacity: 0;
	transform: translateY(-20px);
}

.enterActive {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 300ms ease-in, transform 300ms ease-in;
}

.exit {
	opacity: 1;
	transform: translateY(0);
}

.exitActive {
	opacity: 0;
	transform: translateY(-20px);
	transition: opacity 300ms ease-out, transform 300ms ease-out;
}
