@import '../../../../../../assets/sass/variables';

.component {
	width: 100%;
	height: var(--app-height);
	position: fixed;
	z-index: 9999;
	background: #0B0B0B;
}

.row {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(var(--width) * 4);
	transform: translateX(var(--translateX));
	height: 100%;
	display: grid;
	grid-auto-flow: column;
	transition: transform .3s;
	overflow: hidden;
}

.slide {
	height: 100%;
	width: var(--width);
	position: relative;

	&:first-of-type {
		background: url(../images/slide-01.png) 50% 0 no-repeat;
		background-size: 100%;
	}

	&:nth-of-type(2) {
		background: url(../images/slide-02.png) 100% 0 no-repeat;
		background-size: 100%;
	}

	&:nth-of-type(3) {
		background: url(../images/slide-03.png) 100% 0 no-repeat;
		background-size: 100%;
	}

	&:nth-of-type(4) {
		background: url(../images/slide-04.png) 100% 25% no-repeat;
		background-size: 100%;
		text-align: center;
	}
}

.bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	width: var(--width);
	display: grid;
	gap: 40px;
	padding-bottom: 20px;
}

.pads {
	display: grid;
	gap: 6px;
	justify-content: center;
	grid-auto-flow: column;
	justify-items: center;
}

.pad {
	width: 23px;
	height: 5px;
	border-radius: 5px;
	transition: opacity .3s;
	opacity: .2;
	background: #fff;

	&.active {
		opacity: 1;
	}
}

.button {
	width: calc(100% - 40px);
	margin: 0 auto;
	border-radius: 55px;
	background: #FFD754;
	height: 64px;
	display: grid;
	align-items: center;
	justify-content: center;
	color: #000;
	font-size: 18px;
	font-weight: 700;
}

.text {
	display: grid;
	gap: 12px;
	position: absolute;

	.slide:first-of-type & {
		text-align: center;
		padding: 0 40px;
		width: 100%;
		left: 0;
		bottom: 165px;
	}

	.slide:nth-of-type(2) &, .slide:nth-of-type(3) & {
		width: 185px;
		top: 35px;
		right: 30px;
	}

	.slide:nth-of-type(4) & {
		top: 35px;
		width: 100%;
		padding: 0 30px;
		left: 0;
	}
}

.title {
	color: #FFD754;
	font-size: 24px;
	font-weight: 700;
	line-height: 120%;

	.slide:nth-of-type(2) &, .slide:nth-of-type(3) &, .slide:nth-of-type(4) & {
		background-size: 58px;
		background-repeat: no-repeat;
		padding-top: 70px;
	}

	.slide:nth-of-type(2) & {
		background-image: url(../images/friends-icon.png);
		background-position: 0 0;
	}

	.slide:nth-of-type(3) & {
		background-image: url(../images/gems-icon.png);
		background-position: 0 0;
	}

	.slide:nth-of-type(4) & {
		background-image: url(../images/tasks-icon.png);
		background-position: 50% 0;
	}
}

.description {
	color: #FFF;
	font-size: 16px;
	font-weight: 600;
	line-height: 130%;
}
