@import '../../../../../assets/sass/variables';

.component {
  display: grid;
}

.row {
  height: 44px;
  padding: 0 12px;
  border-radius: 14px;
  background: #21211F;
  display: grid;
  align-items: center;
  gap: 24px;
  overflow: hidden;
  grid-template-columns: 1fr auto;
}

.name {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 16px;
  max-width: 180px;
  width: fit-content;

  &.withLink {
    padding-right: 24px;
    background: url(../images/icon.png) right 50% no-repeat;
    background-size: 18px;
  }
}

.eggs {
  color: #FFD754;
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  background: url(../images/eggs.png) right 50% no-repeat;
  background-size: 16px;
}
