@import '../../../../../assets/sass/variables';

.component {
	display: grid;
}

.row {
	height: 50px;
	padding: 0 18px;
	border-radius: 14px;
	background: #212120;
	display: grid;
	position: relative;

	&::before {
		width: 24px;
		height: 24px;
		content: '';
		position: absolute;
		top: -7px;
		left: -9px;
		background-size: 100%;
		background-repeat: no-repeat;
	}

	.component:nth-child(1) & {
		background: linear-gradient(96deg, #8A6F35 -20.65%, #292928 19.26%);

		&::before {
			background-image: url(../images/first-class.svg);
		}
	}

	.component:nth-child(2) & {
		background: linear-gradient(96deg, #4C7092 -20.65%, #292928 19.26%);

		&::before {
			background-image: url(../images/second-class.svg);
		}
	}

	.component:nth-child(3) & {
		background: linear-gradient(96deg, #925C4C -20.65%, #292928 19.26%);

		&::before {
			background-image: url(../images/third-class.svg);
		}
	}
}

.wrapper {
	display: grid;
	align-items: center;
	gap: 24px;
	grid-template-columns: 1fr 150px;
	overflow: hidden;

	.withUsers & {
		grid-template-columns: 1fr 70px 80px;
		gap: 12px;
	}
}

.name {
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	max-width: 180px;
	overflow: hidden;
	line-height: 16px;
	display: grid;
	align-items: center;
	justify-content: left;
	gap: 8px;
	grid-template-columns: auto auto;

	span {
		overflow: hidden;
		max-width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.users {
	padding-left: 20px;
	font-weight: 600;
	color: #BAB7AE;
	font-size: 13px;
	background: url(../images/users.svg) 0 50% no-repeat;
	background-size: 16px;
}

.eggs {
	color: #FFD754;
	font-size: 14px;
	font-weight: 700;
	padding-right: 18px;
	background: url(../images/eggs.png) right 50% no-repeat;
	background-size: 14px;
	text-align: right;
}

.icon {
	width: 18px;
	height: 18px;
}
